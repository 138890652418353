.map-tag {
  background: silver;
  border-radius: 8px;
  height: 16px;
  padding: 3px;
  margin: 3px;
}
.flex-container {
  display: flex;
  align-items: center;
}
.flex-wrap {
  flex-wrap: wrap;
}
.full-height {
  height: inherit;
}
.no-margin {
  margin: 0px !important;
}
.some-margin {
  margin: 4px;
}
.pad-right {
  padding-right: 4px;
}
.text {
  font-family: Arial, Helvetica, sans-serif;
}
.full-width {
  width: 100%;
}
.large-width {
  width: 60%;
}
.input-field {
  border-radius: 4px;
}
